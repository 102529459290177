import Modal from "react-modal";
import React from "react";
import "./modal.component.scss";
import { FaDownload, FaWindowClose } from "react-icons/fa";
import { AiOutlineFacebook, AiOutlineMail } from "react-icons/ai";
import { FiTwitter, FiCopy } from "react-icons/fi";
import {
  OEVK_URL,
  DK_MAX_COLOR,
  EGYUTT_MAX_COLOR,
  FIDESZ_KDNP_MAX_COLOR,
  JOBBIK_MAX_COLOR,
  LMP_MAX_COLOR,
  MAP_JSON_URL,
  MKKP_MAX_COLOR,
  MOMENTUM_MAX_COLOR,
  MSZP_PARPESZED_MAX_COLOR,
} from "../../resources/code/constants";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { withAuth0 } from "@auth0/auth0-react";

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapDetails: this.props.mapDetails,
      summaryData: this.props.summaryData,
    };
    this.getSummaryDataById = this.getSummaryDataById.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resizePoll = this.resizePoll.bind(this);
  }

  componentDidMount() {
    if (
      this.props.route.match.params.oevkId &&
      (Object.keys(this.props.summaryData).length === 0 ||
        Object.keys(this.props.mapDetails).length === 0)
    ) {
      this.getMapData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapDetails !== this.props.mapDetails) {
      this.setState({ mapDetails: this.props.mapDetails });
    }
    if (prevProps.summaryData !== this.props.summaryData) {
      this.setState({ summaryData: this.props.summaryData });
    }
  }

  resizePoll = () => {
    let poll = null;
    const iframes = document.getElementsByTagName("iframe");
    for (let iframe of iframes) {
      if (iframe.src.includes("pegapoll")) {
        poll = iframe;
        poll.id = `pegapoll-embed-${poll.src.split("/").pop()}`;
      }
    }
    if (poll) {
      poll.onload = () => {
        if (window.iFrameResize) {
          window.iFrameResize(
            { heightCalculationMethod: "taggedElement" },
            `#${poll.id.toString()}`
          );
        }
      };
    }
  };

  getMapData() {
    fetch(MAP_JSON_URL + (this.props.auth0.isAuthenticated ? "?timestamp=" + new Date().toISOString() : ''))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          mapDetails: data.oevk[this.props.route.match.params.oevkId],
          summaryData: this.getSummaryDataById(data),
        });
      });
  }

  getSummaryDataById(data) {
    const summaryData =
      data.summaryData[2018].oevk.listás[this.props.route.match.params.oevkId];
    return Object.entries(summaryData)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (_sortedObj, [k, v]) => ({
          ..._sortedObj,
          [k]: v,
        }),
        {}
      );
  }

  closeModal() {
    this.props.onModalClose();
    this.props.route.history.push("/");
  }

  render() {
    const modalStyle = {
      content: {
        inset: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        maxWidth: "1200px"
      },
    };

    const calculatedPercentage = {
      "Fidesz-KDNP": this.state.summaryData["Fidesz-KDNP"],
      Jobbik:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"],
      "MSZP-Párbeszéd":
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"],
      LMP:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"],
      DK:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"],
      Momentum:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"],
      MKKP:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"] +
        this.state.summaryData["MKKP"],
      Együtt:
        this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"] +
        this.state.summaryData["MKKP"] +
        this.state.summaryData["Együtt"],
    };

    const percentageGroupStyle = {
      background: `linear-gradient(to right, 
				${FIDESZ_KDNP_MAX_COLOR} 0, ${FIDESZ_KDNP_MAX_COLOR} ${calculatedPercentage["Fidesz-KDNP"]}%, 
				${JOBBIK_MAX_COLOR} ${calculatedPercentage["Fidesz-KDNP"]}%, ${JOBBIK_MAX_COLOR} ${calculatedPercentage["Jobbik"]}%,
				${MSZP_PARPESZED_MAX_COLOR} ${calculatedPercentage["Jobbik"]}%, ${MSZP_PARPESZED_MAX_COLOR} ${calculatedPercentage["MSZP-Párbeszéd"]}%,
				${LMP_MAX_COLOR} ${calculatedPercentage["MSZP-Párbeszéd"]}%, ${LMP_MAX_COLOR} ${calculatedPercentage["LMP"]}%,
				${DK_MAX_COLOR} ${calculatedPercentage["LMP"]}%, ${DK_MAX_COLOR} ${calculatedPercentage["DK"]}%,
				${MOMENTUM_MAX_COLOR} ${calculatedPercentage["DK"]}%, ${MOMENTUM_MAX_COLOR} ${calculatedPercentage["Momentum"]}%,
				${MKKP_MAX_COLOR} ${calculatedPercentage["Momentum"]}%, ${MKKP_MAX_COLOR} ${calculatedPercentage["MKKP"]}%,
				${EGYUTT_MAX_COLOR} ${calculatedPercentage["MKKP"]}%, ${EGYUTT_MAX_COLOR} ${calculatedPercentage["Együtt"]}%
				)`,
    };

    return (
      <div>
        {this.state.mapDetails && this.state.mapDetails.blocks && (
          <Modal
            isOpen={this.props.modalIsOpen}
            style={modalStyle}
            contentLabel="Example Modal"
            ariaHideApp={false}
            onRequestClose={this.props.onModalClose}
            onAfterOpen={this.resizePoll}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            id="main-modal"
          >
            <div className="close-icon" onClick={this.closeModal}>
              <FaWindowClose size={24} />
            </div>

            <div className="paragraph__title">{this.state.mapDetails.name}</div>
            <div className="oevk-description">
              {this.state.mapDetails.description}
            </div>
            <div className="content-wrapper">
              <div className="section-wrapper">
                <div className="title-wrapper">
                  <div id="pointer">
                    <div className="cnt">1</div>
                  </div>
                  <h3>2018-as országyűlési választás eredményei</h3>
                </div>
                <div className="column-wrapper">
                  <div
                    id="percentage-group"
                    style={percentageGroupStyle}
                    className="percentage-group"
                  ></div>
                  {Object.entries(this.state.summaryData).map(
                    ([key, value]) => {
                      return (
                        <div className="party__wrapper" key={key + value}>
                          <span className="party__name">{key}</span>
                          <div className="party__percentage-line"></div>
                          <span className="party_percentage-number">
                            {value}%
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              {Object.values(
                this.state.mapDetails.blocks.map((block, index) => {
                  return (
                    <div className="section-wrapper" key={index + block.title}>
                      <div className="title-wrapper">
                        <div id="pointer">
                          <div className="cnt">{+index + 2}</div>
                        </div>
                        <h3>{block.title}</h3>
                      </div>

                      <div className="column-wrapper">
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{ __html: block.content }}
                        />
                      </div>
                      {block.poll ? (
                        <iframe
                          title="poll"
                          src={block.poll}
                          frameBorder={0}
                          width={"100%"}
                        ></iframe>
                      ) : null}
                    </div>
                  );
                })
              )}
              <div className="share-button-container">
                <a
                  className="download-file-wrapper"
                  rel="noreferrer"
                  href={`https://valasztok.hu/pdf/${this.props.route.match.params.oevkId}.pdf`}
                  target="_blank"
                >
                  <FaDownload size={24} />
                  <span className="download-file__text">PDF letöltés</span>
                </a>
                <FacebookShareButton
                  url={`${OEVK_URL}${this.props.route.match.params.oevkId}`}
                  className="facebook-button"
                >
                  <AiOutlineFacebook className="facebook-button__share-icon" />
                  Megosztás
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${OEVK_URL}${this.props.route.match.params.oevkId}`}
                  className="twitter-button"
                >
                  <FiTwitter className="twitter-button__share-icon" />
                  Twitter
                </TwitterShareButton>
                <a
                  rel="noreferrer"
                  href={`mailto:?body=${OEVK_URL}${this.props.route.match.params.oevkId}`}
                  target="_blank"
                  className="mail-button"
                >
                  <AiOutlineMail></AiOutlineMail>
                  Küldés e-mailben
                </a>
                <div
                  className="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${OEVK_URL}${this.props.route.match.params.oevkId}`
                    );
                  }}
                >
                  <FiCopy></FiCopy>
                  Másolás vágólapra
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withAuth0(ModalComponent);
