import "./custom-modal.component.scss";
import Modal from "react-modal";
import React from "react";
import { FaWindowClose } from "react-icons/fa";

class CustomModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.resizePoll = this.resizePoll.bind(this);
  }

  resizePoll = () => {
    let poll = null;
    const iframes = document.getElementsByTagName("iframe");
    for (let iframe of iframes) {
      if (iframe.src.includes("pegapoll")) {
        poll = iframe;
        poll.id = `pegapoll-embed-${poll.src.split("/").pop()}`;
      }
    }
    if (poll) {
      poll.onload = () => {
        if (window.iFrameResize) {
          window.iFrameResize(
            { heightCalculationMethod: "taggedElement" },
            `#${poll.id.toString()}`
          );
        }
      };
    }
  };

  render() {
    const modalStyle = {
      content: {
        inset: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        maxWidth: "1200px",
      },
    };

    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        style={modalStyle}
        ariaHideApp={false}
        onRequestClose={this.props.onModalClose}
      >
        <div className="close-icon" onClick={this.props.onModalClose}>
          <FaWindowClose size={24} />
        </div>

        <iframe
            className="custom-poll"
          title="custom-poll"
          id="pegapoll-embed-614b2a8624aa9a0001ce2ef2"
          src="https://pegapoll.com/embedded/poll/614b2a8624aa9a0001ce2ef2"
          width="100%"
          height="20"
          allow="autoplay"
          frameBorder="0"
        ></iframe>
      </Modal>
    );
  }
}

export default CustomModalComponent;
