import { withAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { API } from "../../resources/code/constants";

class RollbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rollbackOptions: [],
      selectedOption: {
        id: "",
        datetime: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    fetch(`${API}/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) =>
        this.setState({
          rollbackOptions: response.ids,
          selectedOption: response.ids[0],
        })
      );
  }

  async handleChange(option) {
    const { id, datetime } = option.target.value;
    this.setState({ selectedOption: { id, datetime } });
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    await fetch(`${API}/rollback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id,
      }),
    });
  }

  render() {
    return (
      <FormControl>
        <InputLabel>Excel dátum</InputLabel>
        <Select
          value={this.state.selectedOption || { id: "", datetime: "" }}
          onChange={this.handleChange}
        >
          {this.state.rollbackOptions.map((option, index) => (
            <MenuItem key={"option-" + index} value={option}>
              {option.datetime}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withAuth0(RollbackComponent);
