import React from "react";
import { FaDownload } from "react-icons/fa";
import "./fileDownload.component.scss";
import { withAuth0 } from "@auth0/auth0-react";

class FileDownloadComponent extends React.Component {

  render() {
    return (
      <a
        className="download-file__wrapper"
        rel="noreferrer"
        href="https://data.valasztok.hu/map.xlsx"
        target="_blank"
      >
        <label className="download-file__select-button">
          <FaDownload size={24} />
          <span className="download-file__text">Táblázat letöltése</span>
        </label>
      </a>
    );
  }
}

export default withAuth0(FileDownloadComponent);
