import Modal from "react-modal";
import React from "react";
import "./editModal.component.scss";
import { FaWindowClose } from "react-icons/fa";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  Fab,
  FormControl,
  Input,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { withAuth0 } from "@auth0/auth0-react";
import { API } from "../../resources/code/constants";
import IconButton from "@material-ui/core/IconButton";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getAccordionChild = this.getAccordionChild.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.handleBlockDelete = this.handleBlockDelete.bind(this);
    this.addBlock = this.addBlock.bind(this);
    this.state = {
      open: false,
      blocks: [],
    };
  }

  getAccordionChild(block, index) {
    const defaultEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(block.content))
    );
    let currentEditorContent = block.content;
    let currentTitle = block.title;
    let currentPoll = block.poll;
    return (
      <Accordion key={block.title + index} className="accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h1>
            {index + 2}. {block.title}
          </h1>
        </AccordionSummary>
        <AccordionDetails key={index}>
          <form autoComplete="off">
            <FormControl required={true} className="title-control">
              <InputLabel htmlFor="title">Cím</InputLabel>
              <Input
                id="title"
                defaultValue={block.title}
                onChange={($event) => (currentTitle = $event.target.value)}
              ></Input>
            </FormControl>
            <FormControl required={false} className="poll-control">
              <InputLabel htmlFor="title">Poll URL</InputLabel>
              <Input
                id="poll"
                defaultValue={block.poll}
                onChange={($event) => (currentPoll = $event.target.value)}
              ></Input>
            </FormControl>
            <Editor
              toolbar={{
                image: {
                  uploadCallback: this.uploadImageCallBack,
                  previewImage: true,
                  alt: { present: true, mandatory: false },
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                },
              }}
              defaultEditorState={defaultEditorState}
              onContentStateChange={(contentState) => {
                currentEditorContent = draftToHtml(contentState);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.save(index, currentTitle, currentEditorContent, currentPoll)
              }
            >
              Mentés
            </Button>
            <IconButton
              className="deleteIcon"
              onClick={() => {
                this.handleBlockDelete(index);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </form>
        </AccordionDetails>
      </Accordion>
    );
  }

  async handleBlockDelete(index) {
    const { blocks } = this.state;
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    const { mapDetails, oevk } = this.props;

    const key = Object.entries(oevk).find(
      ([key, value]) => value.name === mapDetails.name
    )[0];
    oevk[key].blocks.splice(index, 1);
    blocks.splice(index, 1);
    this.setState({ blocks });
    const response = await fetch(`${API}/map-data/oevk/${key}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(oevk[key]),
    });
    if (response.success === true) {
      this.setState({ open: true });
    }
  }

  async uploadImageCallBack(file) {
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    const body = new FormData();
    body.append("file", file, file.name);
    return fetch(`${API}/img-upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then((response) => response.json())
      .then((response) => {
        return { data: { link: response.path } };
      });
  }

  async save(index, title, content, poll) {
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    const { mapDetails, oevk } = this.props;
    const key = Object.entries(oevk).find(
      ([key, value]) => value.name === mapDetails.name
    )[0];
    oevk[key].blocks[index].title = title;
    oevk[key].blocks[index].content = content;
    oevk[key].blocks[index].poll = poll;
    const response = await fetch(`${API}/map-data/oevk/${key}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(oevk[key]),
    });
    if (response.success === true) {
      this.setState({ open: true });
    }
  }

  addBlock() {
    const { blocks } = this.state;
    blocks.push({ title: "", content: "", poll: "" });
    this.setState({ blocks });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const modalStyle = {
      content: {
        inset: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        maxWidth: "1200px",
      },
    };

    return (
      <div>
        {this.props.mapDetails && this.props.mapDetails.blocks && (
          <Modal
            isOpen={this.props.modalIsOpen}
            onRequestClose={this.props.onModalClose}
            style={modalStyle}
            contentLabel="Edit Modal"
            ariaHideApp={false}
            onAfterOpen={() =>
              this.setState({ blocks: this.props.mapDetails.blocks })
            }
          >
            <div className="close-icon" onClick={this.props.onModalClose}>
              <FaWindowClose size={24} />
            </div>
            {Object.values(this.state.blocks).map(this.getAccordionChild)}
            <Fab className="addIcon" color="primary" onClick={this.addBlock}>
              <AddIcon />
            </Fab>
          </Modal>
        )}
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="success">
            Sikeres szerkesztés!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withAuth0(EditModalComponent);
