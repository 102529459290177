export const API = "https://valasztok.hu/api";
export const OEVK_URL = "https://valasztok.hu/korzetek/";

export const FACEBOOK_URL = "https://www.facebook.com/Valasztokhu-107903977777883";
export const INSTAGRAM_URL = "https://www.instagram.com/valasztok.hu";
export const TWITTER_URL = "https://twitter.com/valasztok";

export const MAP_JSON_URL = "https://data.valasztok.hu/map.json";
export const MAP_XLSX_URL = "https://data.valasztok.hu/map.xlsx";

export const DEFAULT_YEAR = "2018";
export const DEFAULT_TYPE = "egyéni";
export const DEFAULT_PARTY = "Fidesz-KDNP";
export const DEFAULT_MAP_TYPE = "oevk";
export const DEFAULT_MIN_VALUE = "28.49%";
export const DEFAULT_MAX_VALUE = "63.9%";
export const DEFAULT_MAP_ID = "Fidesz-KDNP 2018 oevk egyéni";

export const OTHER_TYPE = "egyéb";
export const VALID_FILE_EXTENSIONS = [".xlsx"];

export const DEFAULT_MIN_COLOR = "#fec68d";
export const DEFAULT_MAX_COLOR = "#fd8100";

export const WHITE_SPACE = " ";
export const EMPTY_STRING = "";
export const PERCENTAGE_MARK = "%";

export const FIDESZ_KDNP_MAX_COLOR = "#fd8100";
export const DK_MAX_COLOR = "#007fff";
export const EGYUTT_MAX_COLOR = "#00daff";
export const JOBBIK_MAX_COLOR = "#008371";
export const LMP_MAX_COLOR = "#73c92d";
export const MKKP_MAX_COLOR = "#808080";
export const MSZP_PARPESZED_MAX_COLOR = "#e71a29";
export const MOMENTUM_MAX_COLOR = "#8e6fcd";
