import React from "react";
import "./details.component.scss";
import {
  DK_MAX_COLOR,
  EGYUTT_MAX_COLOR,
  FIDESZ_KDNP_MAX_COLOR,
  JOBBIK_MAX_COLOR,
  LMP_MAX_COLOR,
  MAP_JSON_URL,
  MKKP_MAX_COLOR,
  MOMENTUM_MAX_COLOR,
  MSZP_PARPESZED_MAX_COLOR,
} from "../../resources/code/constants";
import { withAuth0 } from "@auth0/auth0-react";

class DetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapDetails: [],
      summaryData: [],
      dataLoaded: false,
    };
    this.getSummaryDataById = this.getSummaryDataById.bind(this);
    this.resizePoll = this.resizePoll.bind(this);
  }

  componentDidMount() {
    this.getMapData();
  }

  resizePoll = () => {
    let poll = null;
    const iframes = document.getElementsByTagName("iframe");
    for (let iframe of iframes) {
      if (iframe.src.includes("pegapoll")) {
        poll = iframe;
        poll.id = `pegapoll-embed-${poll.src.split("/").pop()}`;
      }
    }
    if (poll) {
      poll.onload = () => {
        if (window.iFrameResize) {
          window.iFrameResize(
            { heightCalculationMethod: "taggedElement" },
            `#${poll.id.toString()}`
          );
        }
      };
    }
  };

  getMapData() {
    fetch(MAP_JSON_URL + (this.props.auth0.isAuthenticated ? "?timestamp=" + new Date().toISOString() : ''))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          mapDetails: data.oevk[this.props.match.params.oevkId],
          summaryData: this.getSummaryDataById(data),
          dataLoaded: true,
        });
      });
  }

  getSummaryDataById(data) {
    const summaryData =
      data.summaryData[2018].oevk.listás[this.props.match.params.oevkId];
    return Object.entries(summaryData)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (_sortedObj, [k, v]) => ({
          ..._sortedObj,
          [k]: v,
        }),
        {}
      );
  }

  render() {
    const calculatedPercentage = {
      "Fidesz-KDNP": this.state.summaryData["Fidesz-KDNP"],
      Jobbik:
        //this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"],
      "MSZP-Párbeszéd":
        //this.state.summaryData["Fidesz-KDNP"] +
        //this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"],
      LMP:
        //this.state.summaryData["Fidesz-KDNP"] +
        //this.state.summaryData["Jobbik"] +
        //this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"],
      DK:
        //this.state.summaryData["Fidesz-KDNP"] +
        //this.state.summaryData["Jobbik"] +
        //this.state.summaryData["MSZP-Párbeszéd"] +
        //this.state.summaryData["LMP"] +
        this.state.summaryData["DK"],
      Momentum:
        //this.state.summaryData["Fidesz-KDNP"] +
        //this.state.summaryData["Jobbik"] +
        //this.state.summaryData["MSZP-Párbeszéd"] +
        //this.state.summaryData["LMP"] +
        //this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"],
      MKKP:
        /*this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"] +*/
        this.state.summaryData["MKKP"],
      Együtt:
        /*this.state.summaryData["Fidesz-KDNP"] +
        this.state.summaryData["Jobbik"] +
        this.state.summaryData["MSZP-Párbeszéd"] +
        this.state.summaryData["LMP"] +
        this.state.summaryData["DK"] +
        this.state.summaryData["Momentum"] +
        this.state.summaryData["MKKP"] +*/
        this.state.summaryData["Együtt"],
    };

    /*const percentageGroupStyle = {
      background: `linear-gradient(to right, 
				${FIDESZ_KDNP_MAX_COLOR} 0, ${FIDESZ_KDNP_MAX_COLOR} ${calculatedPercentage["Fidesz-KDNP"]}%, 
				${JOBBIK_MAX_COLOR} ${calculatedPercentage["Fidesz-KDNP"]}%, ${JOBBIK_MAX_COLOR} ${calculatedPercentage["Jobbik"]}%,
				${MSZP_PARPESZED_MAX_COLOR} ${calculatedPercentage["Jobbik"]}%, ${MSZP_PARPESZED_MAX_COLOR} ${calculatedPercentage["MSZP-Párbeszéd"]}%,
				${LMP_MAX_COLOR} ${calculatedPercentage["MSZP-Párbeszéd"]}%, ${LMP_MAX_COLOR} ${calculatedPercentage["LMP"]}%,
				${DK_MAX_COLOR} ${calculatedPercentage["LMP"]}%, ${DK_MAX_COLOR} ${calculatedPercentage["DK"]}%,
				${MOMENTUM_MAX_COLOR} ${calculatedPercentage["DK"]}%, ${MOMENTUM_MAX_COLOR} ${calculatedPercentage["Momentum"]}%,
				${MKKP_MAX_COLOR} ${calculatedPercentage["Momentum"]}%, ${MKKP_MAX_COLOR} ${calculatedPercentage["MKKP"]}%,
				${EGYUTT_MAX_COLOR} ${calculatedPercentage["MKKP"]}%, ${EGYUTT_MAX_COLOR} ${calculatedPercentage["Együtt"]}%
				)`,
    };*/

    const barStyles = [
      {
        backgroundColor: FIDESZ_KDNP_MAX_COLOR,
        width: calculatedPercentage["Fidesz-KDNP"],
      },
      {
        backgroundColor: JOBBIK_MAX_COLOR,
        width: calculatedPercentage["Jobbik"],
      },
      {
        backgroundColor: MSZP_PARPESZED_MAX_COLOR,
        width: calculatedPercentage["MSZP-Párbeszéd"],
      },
      {
        backgroundColor: LMP_MAX_COLOR,
        width: calculatedPercentage["LMP"],
      },
      {
        backgroundColor: DK_MAX_COLOR,
        width: calculatedPercentage["DK"],
      },
      {
        backgroundColor: MOMENTUM_MAX_COLOR,
        width: calculatedPercentage["Momentum"],
      },
      {
        backgroundColor: MKKP_MAX_COLOR,
        width: calculatedPercentage["MKKP"],
      },
      {
        backgroundColor: EGYUTT_MAX_COLOR,
        width: calculatedPercentage["Együtt"],
      },
    ];

    return (
      this.state.dataLoaded && (
        <div className="page-wrapper__details">
          <div className="paragraph__title">{this.state.mapDetails.name}</div>
          <div className="oevk-description">
            {this.state.mapDetails.description}
          </div>
          <div className="content-wrapper">
            <div className="section-wrapper">
              <div className="title-wrapper">
                <div id="pointer">
                  <div className="cnt">1</div>
                </div>
                <h3>2018-as országyűlési választás eredményei</h3>
              </div>
              <div className="column-wrapper">
                {barStyles.map((element, index) => (
                  <div
                    key={"percentage-group-" + index}
                    id="percentage-group"
                    style={barStyles[index]}
                    className="percentage-group"
                  ></div>
                ))}
                {Object.entries(this.state.summaryData).map(([key, value]) => {
                  return (
                    <div className="party__wrapper" key={key + value}>
                      <span className="party__name">{key}</span>
                      <div className="party__percentage-line"></div>
                      <span className="party_percentage-number">{value}%</span>
                    </div>
                  );
                })}
              </div>
            </div>
            {Object.values(
              this.state.mapDetails.blocks.map((block, index) => {
                return (
                  <div className="section-wrapper" key={index + block.title}>
                    <div className="title-wrapper">
                      <div id="pointer">
                        <div className="cnt">{+index + 2}</div>
                      </div>
                      <h3>{block.title}</h3>
                    </div>

                    <div className="column-wrapper">
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: block.content }}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )
    );
  }
}

export default withAuth0(DetailsComponent);
