import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import HeaderComponent from "../Header/header.component";

class AdminLoginComponent extends React.Component {
  render() {
    return <HeaderComponent></HeaderComponent>;
  }
}

export default withAuth0(AdminLoginComponent);
