import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import AdminLoginComponent from "./components/AdminLogin/adminLogin.component";
import DetailsComponent from "./components/Details/details.component";
import ImpressumComponent from "./components/Impressum/impressum.component";
import DashboardComponent from "./pages/Dashboard/dashboard.component";

function App() {
  return (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
      >
        <Switch>
          <Route exact path="/" component={DashboardComponent} />
          <Route path="/oevk/:oevkId" component={DetailsComponent} />
          <Route path="/korzetek/:oevkId" component={DashboardComponent} />
          <Route path="/admin" component={AdminLoginComponent} />
          <Route path="/impresszum" component={ImpressumComponent}></Route>
        </Switch>
      </Auth0Provider>
    </Router>
  );
}

export default App;
