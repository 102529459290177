import React, { Component } from "react";
import HeaderComponent from "../../components/Header/header.component";
import MapComponent from "../../components/Map/map.component";
import SearchComponent from "../../components/SearchComponent/search.component";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      isSidebarOpen: false,
      isCooperationModalOpen: false,
      isChartsModalOpen: false,
      isCustomModalOpen: false
    };
    this.handleCallback = this.handleCallback.bind(this);
    this.handleSidebarCallback = this.handleSidebarCallback.bind(this);
    this.handleCooperationModalCallback = this.handleCooperationModalCallback.bind(this);
    this.handleChartsModalCallback = this.handleChartsModalCallback.bind(this);
    this.handleCustomModalCallback = this.handleCustomModalCallback.bind(this);
  }

  async handleCallback(isDrawerOpen) {
    await this.setState({ isDrawerOpen });
  }

  async handleSidebarCallback(isSidebarOpen) {
    await this.setState({ isSidebarOpen });
  }

  async handleCooperationModalCallback(isCooperationModalOpen) {
    await this.setState({ isCooperationModalOpen });
  }

  async handleChartsModalCallback(isChartsModalOpen) {
    await this.setState({ isChartsModalOpen });
  }

  async handleCustomModalCallback(isCustomModalOpen) {
    await this.setState({ isCustomModalOpen });
  }

  render() {
    return (
      <div className="app">
        <div className="app-frame">
          <div className="app-frame__main">
            <HeaderComponent
              isDrawerOpen={this.state.isDrawerOpen}
              isSidebarOpen={this.state.isSidebarOpen}
              handleDrawerOpenCallback={this.handleCallback}
              handleSidebarOpenCallback={this.handleSidebarCallback}
              route={this.props}
            />
            <MapComponent
              route={this.props}
              isDrawerOpen={this.state.isDrawerOpen}
              isSidebarOpen={this.state.isSidebarOpen}
              handleSidebarOpenCallback={this.handleSidebarCallback}
              handleDrawerOpenCallback={this.handleCallback}
              handleCooperationModalCallback={this.handleCooperationModalCallback}
              handleChartsModalCallback={this.handleCooperationModalCallback}
              handleCustomModalCallback={this.handleCustomModalCallback}
            />
            <SearchComponent className="desktop-search"></SearchComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardComponent;
