import React, { Component } from "react";
import {
  DEFAULT_MAP_TYPE,
  DEFAULT_MAX_COLOR,
  DEFAULT_MIN_COLOR,
  DEFAULT_PARTY,
  DEFAULT_TYPE,
  DEFAULT_YEAR,
  MAP_JSON_URL,
  WHITE_SPACE,
  DEFAULT_MIN_VALUE,
  DEFAULT_MAX_VALUE,
  PERCENTAGE_MARK,
  DEFAULT_MAP_ID,
  EMPTY_STRING,
  OTHER_TYPE,
} from "../../resources/code/constants";
import "./map.component.scss";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ModalComponent from "../Modal/modal.component";
import EditModalComponent from "../EditModal/editModal.component";
import { withAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CooperationModalComponent from "../CooperationModal/cooperation-modal.component";
import ChartsModalComponent from "../ChartsModal/charts-modal.component";
import CustomModalComponent from "../CustomModal/custom-modal.component";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maps: {},
      oevk: {},
      selectedMapDetails: {},
      summaryData: {},
      selectedSummaryData: {},
      partyList: [],
      otherList: [],
      isOtherTypeSelected: false,
      isPartyOrOther: "party",
      modalIsOpen: false,
      mapId: DEFAULT_MAP_ID,
      type: DEFAULT_TYPE,
      year: DEFAULT_YEAR,
      mapType: DEFAULT_MAP_TYPE,
      party: DEFAULT_PARTY,
      maxColor: DEFAULT_MAX_COLOR,
      minColor: DEFAULT_MIN_COLOR,
      maxOtherColor: EMPTY_STRING,
      minOtherColor: EMPTY_STRING,
      minPercentage: DEFAULT_MIN_VALUE,
      maxPercentage: DEFAULT_MAX_VALUE,
      searchResultTooltip: null,
      isChartsDataModalOpen: false,
      isCooperationModalOpen: false,
      isCustomModalOpen: false
    };
    this.changeMap = this.changeMap.bind(this);
    this.concatMapId = this.concatMapId.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.onchangeMapType = this.onchangeMapType.bind(this);
    this.onChangePartyOrOther = this.onChangePartyOrOther.bind(this);
    this.getMenuItems = this.getMenuItems.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.getMapDetails = this.getMapDetails.bind(this);
    this.getSummaryDataById = this.getSummaryDataById.bind(this);
    this.getMapData = this.getMapData.bind(this);
    this.onEditModalClose = this.onEditModalClose.bind(this);

    this.isMapTypeSelected = this.isMapTypeSelected.bind(this);
    this.isTypeSelected = this.isTypeSelected.bind(this);
    this.isYearSelected = this.isYearSelected.bind(this);
    this.isPartyOrOtherSelected = this.isPartyOrOtherSelected.bind(this);

    this.onChartsDataModalClose = this.onChartsDataModalClose.bind(this);
    this.openChartsDataModal = this.openChartsDataModal.bind(this);

    this.onCooperationDataModalClose =
      this.onCooperationDataModalClose.bind(this);
    this.openCooperationModal = this.openCooperationModal.bind(this);

    this.onCustomModalClose =
      this.onCustomModalClose.bind(this);
    this.openCustomModal = this.openCustomModal.bind(this);

    window.addEventListener(
      "message",
      (event) => {
        if (event.data.mapControl && this.state.mapType === DEFAULT_MAP_TYPE) {
          const id = event.data.openPopup;
          this.setState({
            selectedMapDetails: this.getMapDetails(id),
            selectedSummaryData: this.getSummaryDataById(id),
            modalIsOpen: true,
          });
          this.props.route.history.push(`/korzetek/${id}`);
        }

        if (event.data.searchResult) {
          this.setState({
            searchResultTooltip: {
              ...this.getMapDetails(event.data.oevkId),
              oevkId: event.data.oevkId,
            },
          });
        }
      },
      false
    );

    // First we get the viewport height of the map and we multiple it by 1% to get a value for a vh unit
    let vh = (window.innerHeight - 49) * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  componentDidMount() {
    this.getMapData();

    if (this.props.route.match.params.oevkId) {
      this.setState({ modalIsOpen: true });
    }
  }

  getMapData() {
    fetch(
      MAP_JSON_URL +
        (this.props.auth0.isAuthenticated
          ? "?timestamp=" + new Date().toISOString()
          : "")
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            oevk: data.oevk,
            maps: data.maps,
            summaryData: data.summaryData,
            summaryData2: data.summaryData2,
            telepulesek: data.telepulesek,
            partyList: this.getMenuItems(
              data.maps,
              this.state.year,
              this.state.type,
              this.state.mapType
            ),
            otherList: this.getMenuItems(
              data.maps,
              this.state.year,
              OTHER_TYPE,
              this.state.mapType
            ),
          },
          () => this.changeMap("Fidesz-ellenzék különbsége", true)
        );
      });
  }

  componentWillUnmount() {
    this.setState({ modalIsOpen: false });
  }

  getMenuItems(data, year, type, mapType) {
    return Object.entries(data)
      .filter(
        ([_, { metadata }]) =>
          metadata.year === year &&
          metadata.type === type &&
          metadata.mapType === mapType
      )
      .map(([_, { metadata }]) => metadata.title || metadata.party);
  }

  async changeMap(prefix, isOtherType) {
    if (isOtherType != null) {
      await this.setState({ isOtherTypeSelected: isOtherType });
    }
    const isSelectedExsiting =
      this.state.partyList.includes(prefix) ||
      this.state.otherList.includes(prefix);
    const party = isSelectedExsiting ? prefix : DEFAULT_PARTY;
    if (!isSelectedExsiting) {
      await this.setState({ isOtherTypeSelected: false });
    }
    const type =
      this.state.isOtherTypeSelected && isSelectedExsiting
        ? OTHER_TYPE
        : this.state.type;
    const mapId = this.concatMapId(party, type);
    const metadata = this.state.maps[mapId].metadata;
    await this.setState({
      mapId,
      party,
      minPercentage: metadata.min + PERCENTAGE_MARK,
      maxPercentage: metadata.max + PERCENTAGE_MARK,
      minColor: metadata.color.min,
      maxColor: metadata.color.max,
    });

    const msg = {
      mapControl: true,
      mapId: mapId,
    };

    document
      .querySelector("#map")
      .contentWindow.postMessage(
        msg,
        process.env.REACT_APP_MAP_IFRAME_URL.toString()
      );
  }

  concatMapId(prefix, type) {
    return (
      prefix +
      WHITE_SPACE +
      this.state.year +
      WHITE_SPACE +
      this.state.mapType +
      WHITE_SPACE +
      type
    );
  }

  async onChangeType(event) {
    const type = event; /*.target.value*/
    await this.setState({
      type,
      partyList: this.getMenuItems(
        this.state.maps,
        this.state.year,
        type,
        this.state.mapType
      ),
      otherList: this.getMenuItems(
        this.state.maps,
        this.state.year,
        OTHER_TYPE,
        this.state.mapType
      ),
    });

    if (!this.state.partyList.includes(this.state.party)) {
      await this.setState({ party: this.state.partyList[0] });
    }

    this.changeMap(this.state.party);
  }

  isTypeSelected(value) {
    return this.state.type === value;
  }

  async onChangeYear(event) {
    const year = event; /*.target.value*/
    await this.setState({
      year,
      partyList: this.getMenuItems(
        this.state.maps,
        year,
        this.state.type,
        this.state.mapType
      ),
      otherList: this.getMenuItems(
        this.state.maps,
        year,
        OTHER_TYPE,
        this.state.mapType
      ),
    });

    if (!this.state.partyList.includes(this.state.party)) {
      await this.setState({ party: this.state.partyList[0] });
    }

    this.changeMap(this.state.party);
  }

  isYearSelected(value) {
    return this.state.year === value;
  }

  async onchangeMapType(event) {
    const mapType = event; /*.target.value*/
    await this.setState({
      mapType,
      partyList: this.getMenuItems(
        this.state.maps,
        this.state.year,
        this.state.type,
        mapType
      ),
      otherList: this.getMenuItems(
        this.state.maps,
        this.state.year,
        OTHER_TYPE,
        mapType
      ),
    });

    if (!this.state.partyList.includes(this.state.party)) {
      await this.setState({ party: this.state.partyList[0] });
    }

    this.changeMap(this.state.party);
  }

  isMapTypeSelected(value) {
    return this.state.mapType === value;
  }

  async toggleDrawer(e) {
    this.props.handleDrawerOpenCallback(!this.props.isDrawerOpen);
    e.preventDefault();
  }

  async closeSidebar(e) {
    this.props.handleSidebarOpenCallback(false);
    e.preventDefault();
  }

  onModalClose() {
    this.setState({ modalIsOpen: false });
  }

  onEditModalClose() {
    this.setState({ modalIsOpen: false });
    this.getMapData();
  }

  onChartsDataModalClose(e) {
    e.stopPropagation();
    this.setState({ isChartsDataModalOpen: false });
  }
  openChartsDataModal() {
    this.setState({ isChartsDataModalOpen: true });
  }

  onCooperationDataModalClose(e) {
    e.stopPropagation();
    this.setState({ isCooperationModalOpen: false });
  }
  openCooperationModal() {
    this.setState({ isCooperationModalOpen: true });
  }

  onCustomModalClose(e) {
    e.stopPropagation();
    this.setState({ isCustomModalOpen: false });
  }
  openCustomModal() {
    this.setState({ isCustomModalOpen: true });
  }

  getMapDetails(id) {
    return this.state.oevk[id];
  }

  getSummaryDataById(id) {
    const summaryData = this.state.summaryData[2018].oevk.listás[id];
    return Object.entries(summaryData)
      .sort((a, b) => b[1] - a[1])
      .reduce(
        (_sortedObj, [k, v]) => ({
          ..._sortedObj,
          [k]: v,
        }),
        {}
      );
  }

  onChangePartyOrOther(value) {
    this.setState({ isPartyOrOther: value });
  }

  isPartyOrOtherSelected(value) {
    return this.state.isPartyOrOther === value;
  }

  render() {
    const { isAuthenticated } = this.props.auth0;
    return (
      <div className="map-wrapper">
        {isAuthenticated ? (
          <EditModalComponent
            modalIsOpen={this.state.modalIsOpen}
            onModalClose={this.onEditModalClose}
            maps={this.state.maps}
            oevk={this.state.oevk}
            telepulesek={this.state.telepulesek}
            mapDetails={this.state.selectedMapDetails}
            summaryData={this.state.summaryData}
          ></EditModalComponent>
        ) : (
          <ModalComponent
            route={this.props.route}
            modalIsOpen={this.state.modalIsOpen}
            onModalClose={this.onModalClose}
            maps={this.state.maps}
            mapDetails={this.state.selectedMapDetails}
            summaryData={this.state.selectedSummaryData}
          />
        )}
        <Drawer
          anchor="right"
          open={this.props.isDrawerOpen}
          onClose={this.toggleDrawer}
        >
          <List className="drawer-list">
            <div className="drawer-list__sidebar-filter--color-wrapper">
              <div
                className="drawer-list__sidebar-filter--color"
                style={{
                  backgroundImage: `linear-gradient(to right, ${this.state.minColor}, ${this.state.maxColor})`,
                }}
              />
              <div className="drawer-list__sidebar-filter--value-percentage">
                <span>{this.state.minPercentage}</span>
                <span>{this.state.maxPercentage}</span>
              </div>
            </div>
          </List>
          <List className="drawer-list">
            <div className="drawer-list__sidebar-filter">
              <div
                className="drawer-list__sidebar-filter--wrapper"
                onChange={this.onchangeMapType}
              >
                <label className="drawer-list__sidebar-filter--element">
                  <input
                    type="radio"
                    value="oevk"
                    defaultChecked
                    name="filter_type_1"
                  />
                  <span>Választókerület</span>
                </label>
                <label className="drawer-list__sidebar-filter--element">
                  <input type="radio" value="telepules" name="filter_type_1" />
                  <span>Település</span>
                </label>
              </div>
              <div
                className="drawer-list__sidebar-filter--wrapper"
                onChange={this.onChangeType}
              >
                <label className="drawer-list__sidebar-filter--element">
                  <input type="radio" value="listás" name="filter_type_2" />
                  <span>Listás szavazat</span>
                </label>
                <label className="drawer-list__sidebar-filter--element">
                  <input
                    type="radio"
                    value="egyéni"
                    defaultChecked
                    name="filter_type_2"
                  />
                  <span>Egyéni szavazat</span>
                </label>
              </div>
              <div
                className="drawer-list__sidebar-filter--wrapper"
                onChange={this.onChangeYear}
              >
                <label className="drawer-list__sidebar-filter--element">
                  <input
                    type="radio"
                    value="2018"
                    defaultChecked
                    name="filter_type_3"
                  />
                  <span>2018-as eredmények</span>
                </label>
                <label className="drawer-list__sidebar-filter--element">
                  <input type="radio" value="2014" name="filter_type_3" />
                  <span>2014-es eredmények</span>
                </label>
                <label className="drawer-list__sidebar-filter--element">
                  <input type="radio" value="2018-2014" name="filter_type_3" />
                  <span>2018-2014-es eredmények</span>
                </label>
              </div>
            </div>
          </List>
          {this.state.partyList && this.state.partyList.length && (
            <List className="drawer-list">
              <div className="drawer-list--title-wrapper">
                <span className="drawer-list--title">Összes párt</span>
              </div>
              {this.state.partyList.map((party, index) => (
                <div key={index} onClick={() => this.changeMap(party, false)}>
                  <ListItem>
                    <span>{party}</span>
                  </ListItem>
                  <Divider className="drawer-list--divider" />
                </div>
              ))}
            </List>
          )}
          {this.state.otherList && this.state.otherList.length && (
            <List className="drawer-list">
              <div className="drawer-list--title-wrapper">
                <span className="drawer-list--title">Egyéb</span>
              </div>
              {this.state.otherList.map((other, index) => (
                <div key={index} onClick={() => this.changeMap(other, true)}>
                  <ListItem>
                    <span>{other}</span>
                  </ListItem>
                  <Divider className="drawer-list--divider" />
                </div>
              ))}
              <div>
                <ListItem onClick={() => this.openCooperationModal()}>
                  <span>Ellenzéki együttműködés</span>
                </ListItem>
                <Divider className="drawer-list--divider" />
                <CooperationModalComponent
                  route={this.props.route}
                  modalIsOpen={this.state.isCooperationModalOpen}
                  onModalClose={this.onCooperationDataModalClose}
                />
              </div>
              <div>
                <ListItem onClick={() => this.openChartsDataModal()}>
                  <span>Elemzéseink</span>
                </ListItem>
                <ChartsModalComponent
                  route={this.props.route}
                  modalIsOpen={this.state.isChartsDataModalOpen}
                  onModalClose={this.onChartsDataModalClose}
                />
              </div>
              <Divider className="drawer-list--divider" />
              {/* <div>
                <ListItem onClick={() => this.openCustomModal()}>
                  <span>Miniszterelnök jelöltek</span>
                </ListItem>
                <CustomModalComponent
                  route={this.props.route}
                  modalIsOpen={this.state.isCustomModalOpen}
                  onModalClose={this.onCustomModalClose}
                />
              </div>
              <Divider className="drawer-list--divider" />
              <div>
                <ListItem>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/d/u/0/viewer?mid=1mPMtJ8aH9qqpmhkH-bin5l3rGr902JmP&ll=47.180287230730016%2C19.505685500000006&z=8"
                  >
                    Előválasztási sátrak
                  </a>
                </ListItem>
              </div> */}
              <Divider className="drawer-list--divider" />
              <div>
                <ListItem>
                  <a href="/impresszum">Impresszum</a>
                </ListItem>
              </div>
            </List>
          )}
        </Drawer>
        <div className="main-content">
          <iframe
            title="map"
            id="map"
            src={process.env.REACT_APP_MAP_IFRAME_URL}
            className="map"
          />
          {/*<div className="dropdown-wrapper" style={{ background: 'red' }}>
						<div className="dropdown">
							<button className="dropbtn">Összes párt</button>
							<div className="dropdown-content">
								{this.state.partyList.map((group, index) => {
									return (<span onClick={() => this.changeMap(group, false)} key={index}>{group}</span>)
								})}
							</div>
						</div>
						<div className="dropdown">
							<button className="dropbtn">Egyéb</button>
							<div className="dropdown-content">
								{this.state.otherList.map((other, index) => {
									return (<span onClick={() => this.changeMap(other, true)} key={index}>{other}</span>)
								})}
							</div>
						</div>
							</div>*/}
        </div>
        {this.state.searchResultTooltip && (
          <div className="search-result-tooltip">
            <span className="search-result-name">
              {this.state.searchResultTooltip.name}
            </span>
            <div className="row">
              <SearchIcon
                className="search-icon"
                onClick={() => {
                  const { oevkId } = this.state.searchResultTooltip;
                  this.setState({
                    selectedMapDetails: this.getMapDetails(oevkId),
                    selectedSummaryData: this.getSummaryDataById(oevkId),
                    modalIsOpen: true,
                  });
                  this.props.route.history.push(`/korzetek/${oevkId}`);
                }}
              ></SearchIcon>
              <span className="search-result-description">
                {this.state.searchResultTooltip.description}
              </span>
            </div>
          </div>
        )}
        {this.props.isSidebarOpen && (
          <div className="sidebar">
            <div className="row first">
              <span className="first-row-title">Térképválasztó</span>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={this.closeSidebar}
              ></CloseIcon>
            </div>
            <div className="row second">
              <div
                className={`option ${
                  this.isMapTypeSelected("oevk") ? "selected" : ""
                }`}
                onClick={() => this.onchangeMapType("oevk")}
              >
                Választókerület
              </div>
              <div
                className={`option ${
                  this.isMapTypeSelected("telepules") ? "selected" : ""
                }`}
                onClick={() => this.onchangeMapType("telepules")}
              >
                Település
              </div>
            </div>
            <div className="row third">
              <div
                className={`option ${
                  this.isTypeSelected("listás") ? "selected" : ""
                }`}
                onClick={() => this.onChangeType("listás")}
              >
                Listás
              </div>
              <div
                className={`option ${
                  this.isTypeSelected("egyéni") ? "selected" : ""
                }`}
                onClick={() => this.onChangeType("egyéni")}
              >
                Egyéni
              </div>
            </div>
            <div className="row fourth">
              <div
                className={`option ${
                  this.isYearSelected("2014") ? "selected" : ""
                }`}
                onClick={() => this.onChangeYear("2014")}
              >
                2014
              </div>
              <div
                className={`option ${
                  this.isYearSelected("2018") ? "selected" : ""
                }`}
                onClick={() => this.onChangeYear("2018")}
              >
                2018
              </div>
              <div
                className={`option ${
                  this.isYearSelected("2018-2014") ? "selected" : ""
                }`}
                onClick={() => this.onChangeYear("2018-2014")}
              >
                2018-2014
              </div>
            </div>
            <div className="mapList">
              {this.state.partyList &&
                this.state.partyList.length &&
                this.state.partyList.map((party, index) => (
                  <div
                    className="mapListItem"
                    key={index}
                    onClick={() => this.changeMap(party, false)}
                  >
                    {party}
                  </div>
                ))}
              {this.state.otherList &&
                this.state.otherList.length &&
                this.state.otherList.map((other, index) => (
                  <div
                    className="mapListItem"
                    key={index}
                    onClick={() => this.changeMap(other, true)}
                  >
                    {other}
                  </div>
                ))}
            </div>
            {/*<div className="sidebar__filter">
						<div className="sidebar__filter--wrapper" onChange={this.onchangeMapType}>
							<label className="sidebar__filter--element">
								<input type="radio" value="oevk" defaultChecked name="filter_type_1" />
								<span>Választókerület</span>
							</label>
							<label className="sidebar__filter--element">
								<input type="radio" value="telepules" name="filter_type_1" />
								<span>Település</span>
							</label>
						</div>
						<div className="sidebar__filter--wrapper" onChange={this.onChangeType}>
							<label className="sidebar__filter--element">
								<input type="radio" value="listás" name="filter_type_2" />
								<span>Listás szavazat</span>
							</label>
							<label className="sidebar__filter--element">
								<input type="radio" value="egyéni" defaultChecked name="filter_type_2" />
								<span>Egyéni szavazat</span>
							</label>
						</div>
						<div className="sidebar__filter--wrapper" onChange={this.onChangeYear}>
							<label className="sidebar__filter--element">
								<input type="radio" value="2018" defaultChecked name="filter_type_3" />
								<span>2018-as eredmények</span>
							</label>
							<label className="sidebar__filter--element">
								<input type="radio" value="2014" name="filter_type_3" />
								<span>2014-es eredmények</span>
							</label>
							<label className="sidebar__filter--element">
								<input type="radio" value="2018-2014" name="filter_type_3" />
								<span>2018-2014-es eredmények</span>
							</label>
						</div>
							</div >
					<div className="sidebar__color" style={{ backgroundImage: `linear-gradient(to right, ${this.state.minColor}, ${this.state.maxColor})` }} />
					<div className="sidebar__value-percentage">
						<span>{this.state.minPercentage}</span>
						<span>{this.state.maxPercentage}</span>
					</div>*/}
          </div>
        )}
        <div className="map-id">
          <span>{this.state.mapId}</span>
        </div>
        <div className="color-bar">
          <div
            className="color"
            style={{
              backgroundImage: `linear-gradient(to right, ${this.state.minColor}, ${this.state.maxColor})`,
            }}
          />
          <div className="value-percentage">
            <span>{this.state.minPercentage}</span>
            <span>{this.state.maxPercentage}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth0(MapComponent);
