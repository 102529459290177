import React from "react";
import { FaFileExcel } from "react-icons/fa";
import { API, EMPTY_STRING, VALID_FILE_EXTENSIONS } from "../../resources/code/constants";
import ClipLoader from "react-spinners/ClipLoader";
import './file-upload.component.scss';
import { withAuth0 } from "@auth0/auth0-react";

class FileUploadComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: false,
    };

    this.filePathset = this.filePathset.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    let validFileExt = this.checkFile(e.target.files[0].name);
    if (validFileExt) {
      const file = e.target.files[0];
      this.setState({ file });
    }
  }

  checkFile(fileName) {
    if (fileName) {
      let fileExt = fileName.substring(fileName.lastIndexOf('.'));
      if (VALID_FILE_EXTENSIONS.indexOf(fileExt) < 0) {
        alert("Nem támogatott fájl!");
        return false;
      }
      return true
    }
  }

  async uploadFile() {
    const formData = new FormData();
    formData.append('data', this.state.file);
    this.setState({ loading: true });
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    fetch(`${API}/xlsx`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then(() => {
      this.setFileDefaultState();
      alert("Feltöltés kész!")
    }).catch(() => {
      this.setFileDefaultState();
      alert("Hiba a fájlfeltöltés során!")
    });
  }

  setFileDefaultState() {
    document.getElementById("file-upload").value = EMPTY_STRING;
    this.setState({
      file: null,
      loading: false
    });
  }

  render() {
    return (
      <div className="upload-file__wrapper">
        <label className="upload-file__select-button">
          <FaFileExcel size={24}/>
          <span className="upload-file__text">Fájl kiválasztása</span>
          <input id="file-upload" type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.filePathset} />
        </label>
        {this.state.file != null &&
          <div className="upload-file__action-container">
            {!this.state.loading ?
              <label className="upload-file__upload-button" onClick={this.uploadFile}>{this.state.file.name} feltöltése</label>
              :
              <ClipLoader size={15} />
            }
          </div>
        }
      </div>
    );
  }
}

export default withAuth0(FileUploadComponent);