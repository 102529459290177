import Modal from "react-modal";
import React from "react";
import "./cooperation-modal.component.scss";
import { FaWindowClose } from "react-icons/fa";
import { MAP_JSON_URL } from "../../resources/code/constants";
import { withAuth0 } from "@auth0/auth0-react";

class CooperationModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperationData: null,
    };
    this.getCooperationData = this.getCooperationData.bind(this);
    // this.resizePoll = this.resizePoll.bind(this);
  }

  componentDidMount() {
    this.getCooperationData();
  }

  /*resizePoll = () => {
    let poll = null;
    const iframes = document.getElementsByTagName("iframe");
    for (let iframe of iframes) {
      if (iframe.src.includes("pegapoll")) {
        poll = iframe;
        poll.id = `pegapoll-embed-${poll.src.split("/").pop()}`;
      }
    }
    if (poll) {
      poll.onload = () => {
        if (window.iFrameResize) {
          window.iFrameResize(
            { heightCalculationMethod: "taggedElement" },
            `#${poll.id.toString()}`
          );
        }
      };
    }
  };*/

  getCooperationData() {
    fetch(MAP_JSON_URL + (this.props.auth0.isAuthenticated ? "?timestamp=" + new Date().toISOString() : ''))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          cooperationData: data.cooperationData,
        });
      });
  }

  render() {
    const modalStyle = {
      content: {
        inset: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        maxWidth: "1200px",
      },
    };

    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          style={modalStyle}
          ariaHideApp={false}
          onRequestClose={this.props.onModalClose}
        >
          <div className="close-icon" onClick={this.props.onModalClose}>
            <FaWindowClose size={24} />
          </div>

          {this.state.cooperationData ? (
            <div
              className="cooperation-content"
              dangerouslySetInnerHTML={{
                __html: this.state.cooperationData.content,
              }}
            ></div>
          ) : null}

          {/*this.props.cooperationData.poll ? (
            <iframe
              title="poll"
              src={this.props.cooperationData.poll}
              frameBorder={0}
              width={"100%"}
            ></iframe>
          ) : null*/}
        </Modal>
      </div>
    );
  }
}

export default withAuth0(CooperationModalComponent);
