import { TextField, ThemeProvider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import "./search.component.scss";
import { createMuiTheme } from "@material-ui/core/styles";

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.getOptions = this.getOptions.bind(this);
  }

  select(city) {
    const msg = {
      focus: true,
      coordinates: [city.center[0], city.center[1]],
    };
    document
      .querySelector("#map")
      .contentWindow.postMessage(msg, "https://valasztok.hu");
    // document.querySelector("#map").contentWindow.postMessage(msg, 'localhost:8080');
  }

  async getOptions(text) {
    const result = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${process.env.REACT_APP_MAP_MAPBOX_TOKEN}&country=HU&types=postcode,district,place,locality,neighborhood,address&language=hu&limit=10`
    ).then((result) => result.json());
    this.setState({
      options: result.features,
    });
  }

  resetView() {
    const msg = {
      resetMap: true,
    };
    document
    .querySelector("#map")
    .contentWindow.postMessage(msg, "https://valasztok.hu");
  }

  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#AA8749",
        },
        secondary: {
          main: "#f44336",
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <Autocomplete
          className={this.props.className}
          id="combo-box-demo"
          noOptionsText="Nincs találat"
          options={this.state.options}
          getOptionLabel={(option) => {
            if (option.properties.accuracy === "street") {
              const city = option.context.find((context) =>
                context.id.startsWith("place")
              ).text_hu;
              return city + ", " + option.text;
            }
            return option.text;
          }}
          style={{ width: 400 }}
          onChange={($event, value, reason) => {
            if (value && value.text) {
              this.select(value);
            }
            if (reason === 'clear') {
              this.resetView();
            }
          }}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              placeholder="Hova tartozom?"
              {...params}
              onChange={($event) => {
                if ($event.target.value === "") {
                  // document.querySelector("#map").contentWindow.resetView();
                } else {
                  this.getOptions($event.target.value);
                }
              }}
            />
          )}
        />
      </ThemeProvider>
    );
  }
}

export default SearchComponent;
