import Modal from "react-modal";
import React from "react";
import "./cooperation-editor-modal.component.scss";
import { FaWindowClose } from "react-icons/fa";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { withAuth0 } from "@auth0/auth0-react";
import { API, MAP_JSON_URL } from "../../resources/code/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CooperationEditorModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.save = this.save.bind(this);
    this.getCooperationData = this.getCooperationData.bind(this);
    this.state = {
      open: false,
      cooperationData: "",
    };
  }

  componentDidMount() {
    this.getCooperationData();
  }

  getCooperationData() {
    fetch(MAP_JSON_URL + (this.props.auth0.isAuthenticated ? "?timestamp=" + new Date().toISOString() : ''))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          cooperationData: data.cooperationData,
        });
      });
  }

  async uploadImageCallBack(file) {
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    const body = new FormData();
    body.append("file", file, file.name);
    return fetch(`${API}/img-upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then((response) => response.json())
      .then((response) => {
        return { data: { link: response.path } };
      });
  }

  async save(currentEditorContent) {
    const token = (await this.props.auth0.getIdTokenClaims()).__raw;
    const response = await fetch(`${API}/map-data/cooperation-data`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ content: currentEditorContent }),
    });
    if (response.success === true) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const modalStyle = {
      content: {
        inset: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        maxWidth: "1200px",
      },
    };

    // const defaultEditorState = EditorState.createEmpty();

    const defaultEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(this.state.cooperationData.content || '<p></p>')
      )
    );

    let currentEditorContent = this.state.currentEditorContent;

    return (
      <div>
        {this.state.cooperationData && (
          <Modal
            isOpen={this.props.modalIsOpen}
            onRequestClose={this.props.onModalClose}
            style={modalStyle}
            contentLabel="Edit Modal"
            ariaHideApp={false}
          >
            <div className="close-icon" onClick={this.props.onModalClose}>
              <FaWindowClose size={24} />
            </div>
            <form autoComplete="off">
              <Editor
                toolbar={{
                  image: {
                    uploadCallback: this.uploadImageCallBack,
                    previewImage: true,
                    alt: { present: true, mandatory: false },
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  },
                }}
                defaultEditorState={defaultEditorState}
                onContentStateChange={(contentState) => {
                  currentEditorContent = draftToHtml(contentState);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.save(currentEditorContent)}
              >
                Mentés
              </Button>
            </form>
          </Modal>
        )}
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="success">
            Sikeres szerkesztés!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withAuth0(CooperationEditorModalComponent);
