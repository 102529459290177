import { Fragment } from "react";
import HeaderComponent from "../Header/header.component";
import "./impressum.component.scss";

const ImpressumComponent = () => {
  return (
    <Fragment>
      <HeaderComponent></HeaderComponent>
      <div className="impressum-container">
        <div>Támogató: Jobbik Magyarországért Alapítvány</div>
        <div>
          <strong>Cégnév:</strong> Iránytű Politikai és Gazdaságkutató Intézet
          Kft.
        </div>
        <div>
          <strong>Székhely: </strong> 1203 Budapest, Közműhelytelep utca 28/b 2.
          Lph. 6.
        </div>
        <div>
          <strong>Elérhetőség: </strong> info@iranytuintezet.hu
        </div>
        <div>
          <strong> Nyilvántartásba vevő cégbíróság:</strong> Fővárosi
          Törvényszék Cégbírósága
        </div>
        <div>
          <strong>Adószám:</strong> 23561465-2-43
        </div>
        <div>
          <strong>Tárhelyszolgáltató neve:</strong> DigitalOcean LLC
        </div>
        <div>
          <strong>Székhelye:</strong> 101 Sixth Ave, New York, NY 10013, United States
        </div>
        <div>
          <strong>Elérhetősége:</strong> +1 212 226 2794
        </div>
      </div>
    </Fragment>
  );
};

export default ImpressumComponent;
