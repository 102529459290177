import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../LogoutButton/logoutButton";
import LoginButton from "../LoginButton/loginButton";
import { useLocation } from "react-router";

const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();

  if (pathname === "/admin" && !isAuthenticated) {
    return <LoginButton />;
  } else if (isAuthenticated) {
    return <LogoutButton />;
  } else {
    return null
  }
};

export default AuthenticationButton;
