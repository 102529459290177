import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import {
  FaHandsHelping,
  FaMap,
  FaFacebook,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { GiBarracksTent } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
} from "../../resources/code/constants";
import Logo from "../../resources/img/icon.png";
import AuthenticationButton from "../AuthenticationButton/authenticationButton";
import ChartsEditorModalComponent from "../ChartsEditorModal/charts-editor-modal.component";
import ChartsModalComponent from "../ChartsModal/charts-modal.component";
import CooperationEditorModalComponent from "../CooperationEditorModal/cooperation-editor-modal.component";
import CooperationModalComponent from "../CooperationModal/cooperation-modal.component";
import FileDownloadComponent from "../FileDownload/fileDownload.component";
import FileUploadComponent from "../FileUpload/file-upload.component";
import RollbackComponent from "../RollbackComponent/rollback.component";
import SearchComponent from "../SearchComponent/search.component";
import MapIcon from "@material-ui/icons/Map";
import "./header.component.scss";
import CustomModalComponent from "../CustomModal/custom-modal.component";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChartsDataModalOpen: false,
      isChartsDataEditorModalOpen: false,
      isCooperationModalOpen: false,
      isCooperationEditorModalOpen: false,
      isCustomModalOpen: false,
    };

    this.onChartsDataModalClose = this.onChartsDataModalClose.bind(this);
    this.onCooperationDataModalClose =
      this.onCooperationDataModalClose.bind(this);

    this.onChartsDataEditorModalClose =
      this.onChartsDataEditorModalClose.bind(this);
    this.onCooperationDataEditorModalClose =
      this.onCooperationDataEditorModalClose.bind(this);

    this.openCooperationModal = this.openCooperationModal.bind(this);
    this.openChartsDataModal = this.openChartsDataModal.bind(this);

    this.openCooperationEditorModal =
      this.openCooperationEditorModal.bind(this);
    this.openChartsDataEditorModal = this.openChartsDataEditorModal.bind(this);

    this.onCustomModalClose = this.onCustomModalClose.bind(this);
    this.openCustomModal = this.openCustomModal.bind(this);

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleDrawer(e) {
    this.props.handleDrawerOpenCallback(!this.props.isDrawerOpen);
    e.preventDefault();
  }

  toggleSidebar(e) {
    this.props.handleSidebarOpenCallback(!this.props.isSidebarOpen);
    e.preventDefault();
  }

  onChartsDataModalClose(e) {
    e.stopPropagation();
    this.setState({ isChartsDataModalOpen: false });
  }
  openChartsDataModal() {
    this.setState({ isChartsDataModalOpen: true });
  }

  onChartsDataEditorModalClose(e) {
    e.stopPropagation();
    this.setState({ isChartsDataEditorModalOpen: false });
  }
  openChartsDataEditorModal() {
    this.setState({ isChartsDataEditorModalOpen: true });
  }

  onCooperationDataModalClose(e) {
    e.stopPropagation();
    this.setState({ isCooperationModalOpen: false });
  }
  openCooperationModal() {
    this.setState({ isCooperationModalOpen: true });
  }

  onCooperationDataEditorModalClose(e) {
    e.stopPropagation();
    this.setState({ isCooperationEditorModalOpen: false });
  }
  openCooperationEditorModal() {
    this.setState({ isCooperationEditorModalOpen: true });
  }

  onCustomModalClose(e) {
    e.stopPropagation();
    this.setState({ isCustomModalOpen: false });
  }
  openCustomModal() {
    this.setState({ isCustomModalOpen: true });
  }

  render() {
    const { isAuthenticated } = this.props.auth0;

    return (
      <div className="header">
        <div className="header__container">
          <a href="/" className="header__container--logo">
            <img src={Logo} alt="Logo"></img>
          </a>
          <div className="header__container--element-wrapper filters">
            <div
              className="header__container--element"
              onClick={this.toggleSidebar}
            >
              <MapIcon></MapIcon>
              <span className="header__container--text">Térképek</span>
            </div>
          </div>
          <div
            className="header__container--element-wrapper cooperation"
            onClick={
              isAuthenticated
                ? this.openCooperationEditorModal
                : this.openCooperationModal
            }
          >
            <div className="header__container--element">
              {isAuthenticated ? (
                <CooperationEditorModalComponent
                  modalIsOpen={this.state.isCooperationEditorModalOpen}
                  onModalClose={this.onCooperationDataEditorModalClose}
                />
              ) : (
                <CooperationModalComponent
                  route={this.props.route}
                  modalIsOpen={this.state.isCooperationModalOpen}
                  onModalClose={this.onCooperationDataModalClose}
                />
              )}
              <FaHandsHelping size={24} className="header__container--button" />
              <span className="header__container--text">
                Ellenzéki együttműködés
              </span>
            </div>
          </div>
          <div
            className="header__container--element-wrapper charts"
            onClick={
              isAuthenticated
                ? this.openChartsDataEditorModal
                : this.openChartsDataModal
            }
          >
            <div className="header__container--element">
              {isAuthenticated ? (
                <ChartsEditorModalComponent
                  modalIsOpen={this.state.isChartsDataEditorModalOpen}
                  onModalClose={this.onChartsDataEditorModalClose}
                />
              ) : (
                <ChartsModalComponent
                  route={this.props.route}
                  modalIsOpen={this.state.isChartsDataModalOpen}
                  onModalClose={this.onChartsDataModalClose}
                />
              )}
              <FaMap size={24} className="header__container--button" />
              <span className="header__container--text">Elemzéseink</span>
            </div>
          </div>
          {/* <div
            className="header__container--element-wrapper charts"
            onClick={this.openCustomModal}
          >
            <div className="header__container--element">
              <CustomModalComponent
                modalIsOpen={this.state.isCustomModalOpen}
                onModalClose={this.onCustomModalClose}
              />
              <IoIosPeople size={24} className="header__container--button" />
              <span className="header__container--text">
                Miniszterelnök jelöltek
              </span>
            </div>
          </div> */}
          {/* <a
            className="header__container--element-wrapper primaries"
            target="blank"
            href="https://www.google.com/maps/d/u/0/viewer?mid=1mPMtJ8aH9qqpmhkH-bin5l3rGr902JmP&ll=47.180287230730016%2C19.505685500000006&z=8"
          >
            <div className="header__container--element">
              <GiBarracksTent size={24} className="header__container--button" />
              <span className="header__container--text">
                Előválasztási sátrak
              </span>
            </div>
          </a> */}
          <SearchComponent className="mobile-search"></SearchComponent>
          {isAuthenticated ? (
            <div className="header__container--element-wrapper">
              <div className="header__container--element">
                <FileUploadComponent />
              </div>
            </div>
          ) : null}
          {isAuthenticated ? (
            <div className="header__container--element-wrapper">
              <div className="header__container--element">
                <FileDownloadComponent />
              </div>
            </div>
          ) : null}
          {isAuthenticated ? (
            <div className="header__container--element-wrapper">
              <div className="header__container--element">
                <RollbackComponent />
              </div>
            </div>
          ) : null}
        </div>
        <div className="header__container--login-wrapper">
          <AuthenticationButton></AuthenticationButton>
        </div>
        <a className="impressum" href="/impresszum">
          Impresszum
        </a>
        <div className="header__container--social-wrapper">
          <a
            className="header__container--social"
            href={FACEBOOK_URL}
            rel="noreferrer"
            target="_blank"
          >
            <FaFacebook size={24} />
          </a>
          <a
            className="header__container--social"
            href={INSTAGRAM_URL}
            rel="noreferrer"
            target="_blank"
          >
            <FaInstagram size={24} />
          </a>
          <a
            className="header__container--social"
            href={TWITTER_URL}
            rel="noreferrer"
            target="_blank"
          >
            <FaTwitter size={24} />
          </a>
        </div>
        <div className="header__container--hamburger">
          <FiMenu
            size={24}
            className="header__container--button"
            onClick={(e) => this.toggleDrawer(e)}
          />
        </div>
      </div>
    );
  }
}

export default withAuth0(HeaderComponent);
